const debounceForSpecialEventUI = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default (() => {
  class SpecialEvent {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      this.isNotificationBannerExistsOnHomePage()
      this._addEventListener()
    }

    _addEventListener() {
      window.addEventListener(
        'resize',
        debounceForSpecialEventUI(e => {
          this.isNotificationBannerExistsOnHomePage()
        }, 100)
      )
    }

    isNotificationBannerExistsOnHomePage() {
      if (window.innerWidth <= 900) {
        if (!this.elems.length) {
          return
        }
        const NOTIFICATION_BANNER_CLASS = '.m-notification-banner'
        const SE_WITHOUT_NOTIFICATION_BANNER_CLASS = 'm-aol-se_without_notif_banner'

        if (document.querySelector(NOTIFICATION_BANNER_CLASS)) {
          this.elems.forEach(elem => {
            elem.classList.remove(SE_WITHOUT_NOTIFICATION_BANNER_CLASS)
          })
        } else {
          this.fixDLOuterWrapperTopMarginForMobile()
          this.elems.forEach(elem => {
            elem.classList.add(SE_WITHOUT_NOTIFICATION_BANNER_CLASS)
          })
        }
      }
    }

    fixDLOuterWrapperTopMarginForMobile() {
      const DL_MOBILE_OUTER_WRAPPER_CLASS = `.dl__outer-wrapper.dl-mobile`
      const DL_MOBILE_OUTER_WRAPPER_SELECTOR = document.querySelector(DL_MOBILE_OUTER_WRAPPER_CLASS)
      if (DL_MOBILE_OUTER_WRAPPER_SELECTOR) {
        DL_MOBILE_OUTER_WRAPPER_SELECTOR.classList.add('dl-special-event')
      }
    }
  }

  return SpecialEvent
})()
